import React, { useState, useEffect, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Product from "../components/product";
import Image from "gatsby-image";
import { getFluid, getSliderGenerator } from "../util/helper";
import Partners from "../components/partners";
import BackgroundImage from "gatsby-background-image";
import { JobsData } from "../data/jobs-data";

interface Industry {
	title: string;
	slug: string;
	description: string;
	icon: string;
}

const IndexPage = () => {
	const { t } = useTranslation("index");

	const jobs = useMemo(() => JobsData(t), []);

	const INDUSTRIES: Industry[] = [
		{
			title: t("index:Aviation"),
			slug: "/industries#aviation",
			description: t(
				"index:We are familiar with airline",
				"We are familiar with airline PSS systems; ERP, CRM and CMS implementations; security standards such as PCI DSS; data governance; eCommerce conversion optimization and SEO principles as well as custom IT development and cloud solutions."
			),
			icon:
				"M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z",
		},
		{
			title: t("index:Tour & Travel"),
			slug: "/industries#tourOperator",
			description: t(
				"index:In the past years DGITAL",
				"In the past years DGITAL has developed a travel service product portfolio with the goal of providing high value and excellent online solutions for Tour Operators and Travel Agencies that can easily be adapted to various business models. Find out more."
			),
			icon:
				"M128 480h256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v400zm64-384h128v32H192V96zm320 80v256c0 26.5-21.5 48-48 48h-48V128h48c26.5 0 48 21.5 48 48zM96 480H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h48v352z",
		},
		{
			title: t("index:eCommerce"),
			slug: "/industries#eCommerce",
			description: t(
				"index:With more than 25 years",
				"With more than 25 years of eCommerce experience we have the solution to IT challenges including our in-house developed ready-to-go web components and cloud modules. Check out our Solutions."
			),
			icon:
				"M624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z",
		},
	];

	interface Service {
		title: string;
		slug: string;
		items: string[];
		icon: string;
	}

	const SERVICES: Service[] = [
		{
			title: t("index:CLOUD"),
			slug: "/services#cloud",
			items: [
				t("index:Migration and optimization"),
				t("index:24/7 operations"),
				t("index:PCI-DSS, GDPR compliancy"),
				t("index:Application development"),
			],
			icon:
				"M1984 1152q0 159-112.5 271.5t-271.5 112.5h-1088q-185 0-316.5-131.5t-131.5-316.5q0-132 71-241.5t187-163.5q-2-28-2-43 0-212 150-362t362-150q158 0 286.5 88t187.5 230q70-62 166-62 106 0 181 75t75 181q0 75-41 138 129 30 213 134.5t84 239.5z",
		},
		{
			title: t("DEVELOPMENT"),
			slug: "/services#development",
			items: [t("index:eCommerce Website"), t("index:Backend systems"), t("index:Software integrations")],
			icon:
				"M681 1399l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23l-393 393 393 393q10 10 10 23t-10 23zm591-1067l-373 1291q-4 13-15.5 19.5t-23.5 2.5l-62-17q-13-4-19.5-15.5t-2.5-24.5l373-1291q4-13 15.5-19.5t23.5-2.5l62 17q13 4 19.5 15.5t2.5 24.5zm657 651l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23t-10 23z",
		},
		{
			title: t("CONSULTING"),
			slug: "/services#consulting",
			items: [
				t("index:Airline and travel IT"),
				t("index:Cloud architecture"),
				t("index:eCommerce"),
				t("index:ERP implementation"),
			],
			icon:
				"M657 896q-162 5-265 128h-134q-82 0-138-40.5t-56-118.5q0-353 124-353 6 0 43.5 21t97.5 42.5 119 21.5q67 0 133-23-5 37-5 66 0 139 81 256zm1071 637q0 120-73 189.5t-194 69.5h-874q-121 0-194-69.5t-73-189.5q0-53 3.5-103.5t14-109 26.5-108.5 43-97.5 62-81 85.5-53.5 111.5-20q10 0 43 21.5t73 48 107 48 135 21.5 135-21.5 107-48 73-48 43-21.5q61 0 111.5 20t85.5 53.5 62 81 43 97.5 26.5 108.5 14 109 3.5 103.5zm-1024-1277q0 106-75 181t-181 75-181-75-75-181 75-181 181-75 181 75 75 181zm704 384q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5zm576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128 81-117 81-256 0-29-5-66 66 23 133 23 59 0 119-21.5t97.5-42.5 43.5-21q124 0 124 353zm-128-609q0 106-75 181t-181 75-181-75-75-181 75-181 181-75 181 75 75 181z",
		},
	];

	interface ExecTeam {
		id: string;
		name: string;
		position: string;
		description: string;
		alt: string;
		fluid?: any;
	}

	const EXEC_TEAM: ExecTeam[] = [
		{
			id: "zsolt",
			name: t("index:Zsolt", "Zsolt S. CSERNÁK"),
			position: t("index:Founder & CEO"),
			description: t(
				"index:Zsolt S Csernák had a vision",
				"Zsolt S. Csernák had a vision of a company that would move the travel tech business to the next level. With his comprehensive knowledge and experience, he founded and is now leading DGITAL. Zsolt played a key role in the strategic planning and management of Wizz Air’s state of the art e-commerce services, infrastructure and IT team."
			),
			alt: t("index:Zsolt", "Zsolt S. Csernák founder and CEO of DGITAL"),
		},
		{
			id: "peter",
			name: t("index:Péter GIGLER"),
			position: t("index:Partner & CTO"),
			description: t(
				"index:Tech Lead/Team Leader with",
				"Tech Lead/Team Leader with general IT designer skills. Experience in building up/leading IT teams and adaptation of methods. Collecting business requirements and keeping contact with clients. Formerly an E-Commerce Analyst at Wizz Air responsible for Mobile, B2B channels and Cloud."
			),
			alt: t("index:Péter Gigler partner and CTO at DGITAL"),
		},
		{
			id: "balazs",
			name: t("index:Balazs", "Balázs TÖVISHÁZY-F."),
			position: t("index:Partner & COO"),
			description: t(
				"index:Head of Operations and",
				"Head of Operations and IT Business Analyst with 10+ years of experience in ULCC airline business. Co-ordinates and negotiates with suppliers. Became expert of IT operations while working as Head of Service Desk and Senior Enterprise Application Manager at Wizz Air."
			),
			alt: t("index:Balázs Tövisházy-Ferjentsik head of operations at DGITAL"),
		},
	];

	const data = useStaticQuery(graphql`
		query IndexPageQuery {
			allFile(
				filter: {
					relativePath: {
						in: [
							"banner/home.jpg"
							"pci_dss_logo.png"
							"microsoft_silver.png"
							"home_products1.jpg"
							"home_products2.jpg"
							"home_products3.jpg"
							"zsolt_csernak_ceo.jpg"
							"peter_gigler_cto.png"
							"tfb_coo.jpg"
						]
					}
				}
			) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
			allMarkdownRemark(limit: 5, skip: 0, sort: { fields: frontmatter___date, order: DESC }) {
				edges {
					node {
						id
						frontmatter {
							slug
							title
						}
						excerpt(pruneLength: 100)
					}
				}
			}
		}
	`);

	const blogItemHeight = 100;

	const [blogItemSettings, setBlogItemSettings] = useState<{ count: number; top: number; clazz: string }>({
		count: 0,
		top: 0,
		clazz: "",
	});

	useEffect(() => {
		const duration = 1000;
		const sliderGen = getSliderGenerator({ numberOfItems: 5, duration, multiplier: 8, height: blogItemHeight });
		const intervalTransitionFunc = window.setInterval(() => {
			setBlogItemSettings((settings) => {
				const { count, top, action } = sliderGen(settings.count);
				if (action === "transform") {
					return {
						count,
						top,
						clazz: `duration-${duration}`,
					};
				} else if (action === "reset") {
					return { count, top, clazz: "" };
				} else {
					return { ...settings, count };
				}
			});
		}, duration);

		return () => {
			window.clearInterval(intervalTransitionFunc);
		};
	}, []);

	const fluidBanner = getFluid(data.allFile.edges, "banner/home.jpg");

	const imgMicrosoftFluid = getFluid(data.allFile.edges, "microsoft_silver.png");
	const imgPciDssFluid = getFluid(data.allFile.edges, "pci_dss_logo.png");
	const imgHomeProductsFluid1 = getFluid(data.allFile.edges, "home_products1.jpg");
	const imgHomeProductsFluid2 = getFluid(data.allFile.edges, "home_products2.jpg");
	const imgHomeProductsFluid3 = getFluid(data.allFile.edges, "home_products3.jpg");

	const zsoltFluid = getFluid(data.allFile.edges, "zsolt_csernak_ceo.jpg");
	const peterFluid = getFluid(data.allFile.edges, "peter_gigler_cto.png");
	const tfbFluid = getFluid(data.allFile.edges, "tfb_coo.jpg");

	[zsoltFluid, peterFluid, tfbFluid].forEach((item, i) => {
		EXEC_TEAM[i].fluid = item;
	});

	return (
		<Layout>
			<SEO title="Home" />
			<div className="text-brand-grayt">
				{/* Banner */}
				<BackgroundImage
					id="banner"
					Tag="div"
					className="bg-gray-400 text-white bg-cover h-350px sm:h-400px"
					fluid={fluidBanner}
				>
					<div className="container">
						<div className="xl:w-2/3 px-2 sm:px-4">
							<h1 className="pt-10 sm:pt-18 text-2xl sm:text-3xl md:text-4xl leading-tight font-bold">
								<Trans i18nKey="Enterprise_cloud" ns="index">
									Enterprise Cloud Software Development, Operations and Consulting
								</Trans>
							</h1>
							<p className="text-base sm:text-lg mt-4 sm:mt-6">
								<Trans i18nKey="Dgital_labs_is_an_ecommerce" ns="index">
									DGITAL Labs is an eCommerce focused technology company that has a unique set of
									know-how gained in the aviation industry. We provide high performance eCommerce
									solutions and cloud services such as custom software development, infrastructure
									design and 24/7 operations.
								</Trans>
							</p>
						</div>
					</div>
				</BackgroundImage>
				{/* Industries */}
				<div id="industries">
					<div className="container p-4">
						<h1 className="mb-6 text-3xl my-3 font-bold">
							<Trans>index:Industries</Trans>
						</h1>
						<div className="grid grid-cols-1 md:grid-cols-3 col-gap-4 mb-5">
							{INDUSTRIES.map((industry) => (
								<div className="w-full h-full relative mb-4 pr-4 pb-8 md:pb-10" key={industry.title}>
									<div className="mb-4">
										<svg
											className="w-10 h-10 fill-current inline"
											viewBox="0 0 700 650"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d={industry.icon} />
										</svg>
										<div className="text-xl ml-3 inline font-bold uppercase">{industry.title}</div>
									</div>
									<p>{industry.description}</p>
									<Link
										to={industry.slug}
										className="text-lg text-brand-green hover:text-brand-gray2 mb-4 mr-6 uppercase absolute bottom-0 right-0 focus:outline-none"
									>
										<Trans>index:Read more</Trans> &#10230;
									</Link>
								</div>
							))}
						</div>
					</div>
				</div>
				{/* Products */}
				<div id="products" className="bg-brand-gray4">
					<div className="container p-4">
						<h1 className="mb-6 mt-2 text-3xl font-bold">
							<Trans>index:Products</Trans>
						</h1>
						<Product
							regular={true}
							fluid={imgHomeProductsFluid1}
							alt={t("index:Travel Engine")}
							body={
								<>
									<h2 className="font-semibold text-2xl uppercase mb-4">
										<span className="text-brand-green">// DEEP</span>{" "}
										<Trans>Travel Reservation System</Trans>
									</h2>
									<Trans i18nKey="New_and_clean" ns="index">
										<p className="text-xl mb-5 font-semibold">
											New and clean solution for tour operators optimized for high volume online
											sales
										</p>
										<p className="text-lg mb-2">
											Researching popular travel reservation products we have discovered the need
											for a new solution that can address several weaknesses of the market
											portfolio like high price, slow time to market, complexity, difficult
											operations and legacy technical platform. Since 2015 we’ve been relentlessly
											working on a new travel reservation software, DEEP, that can address the
											above issues.
										</p>
										<p className="text-lg mb-2">
											DEEP is developed from the ground-up using the latest technologies. Our team
											has designed the product to meet the needs of the challenging eCommerce
											business while we also provide exceptional support during the operations sas
											well. It is optimized for modern tour operators and travel agencies with
											high volume online sales for managing their entire sales portfolio including
											direct sales on the website, personal sales in the offices and the
											connection to re-seller agencies.
										</p>
									</Trans>
									<div className="text-right">
										<Link
											to="/products/travel-reservation-system"
											className="text-lg text-brand-green hover:text-brand-gray1 inline uppercase"
										>
											<Trans>index:Read more</Trans> &#10230;
										</Link>
									</div>
								</>
							}
						/>
						<Product
							regular={false}
							fluid={imgHomeProductsFluid2}
							alt={t("index:Travel Engine")}
							body={
								<>
									<h2 className="font-semibold text-2xl uppercase mb-4">
										<span className="text-brand-green">// DEEP</span>{" "}
										<Trans>index:Airline Internet Booking Engine</Trans>
									</h2>
									<Trans i18nKey="Compatible with major passenger" ns="index">
										<p className="text-xl mb-5 font-semibold">
											Compatible with major passenger service systems
										</p>
										<p className="text-lg mb-2">
											At a start-up airline there are many challenges that need to be solved
											before the launch including major IT and eCommerce decisions. A significant
											task is always to build a modern website as quickly as possible that is
											capable of being extended in the future with different ancillaries and more
											complex features.
										</p>
										<p className="text-lg mb-4">
											In order to overcome this issue, the best solution usually is to create a
											custom developed booking engine that is modern, serves all of the business
											needs and compatible with the selected Passenger Service System (PSS). On
											the other hand, it can be exactly unlike the goals, not low-cost and not
											quick.
										</p>
										<p className="text-lg mb-4">
											DEEP Airline Internet Booking Engine is developed from the ground-up using
											latest technologies. Our team has learned from the market and designed the
											product to meet the needs of the modern business demands. We created a
											booking engine that is lightweight and can grow simultaneously with the
											business to handle complexity.
										</p>
									</Trans>
									<div className="text-right">
										<Link
											to="/products/airline-booking-engine"
											className="text-lg text-brand-green hover:text-brand-grayt inline uppercase"
										>
											<Trans>index:Read more</Trans> &#10230;
										</Link>
									</div>
								</>
							}
						/>
						<Product
							regular={true}
							fluid={imgHomeProductsFluid3}
							alt={t("index:Travel Engine")}
							body={
								<>
									<h2 className="font-semibold text-2xl uppercase mb-4">
										<span className="text-brand-green">// DEEP</span> <Trans>index:Connector</Trans>
									</h2>
									<Trans i18nKey="Fast and seamless integration" ns="index">
										<p className="text-xl mb-5 font-semibold">
											Fast and seamless integration of travel ancillary components to any
											eCommerce website
										</p>
										<p className="text-lg mb-2">
											DEEP Travel Connector is a set of universal component models, that have been
											created with the mindset to fit into any booking flow and to be easily
											extended with any third-party service provider for support fast
											implementation and flexible reinforcements.
										</p>
										<p className="text-lg mb-4">
											Optimized for high traffic and seamless website integration, while keeping
											the operational costs moderate and controlled.
										</p>
									</Trans>
									<div className="text-right">
										<Link
											to="/products/travel-connector"
											className="text-lg text-brand-green hover:text-brand-grayt inline uppercase"
										>
											<Trans>index:Read more</Trans> &#10230;
										</Link>
									</div>
								</>
							}
						/>
					</div>
				</div>
				{/* Services */}
				<div id="services" className="bg-white">
					<div className="container p-4">
						<h1 className="mb-4 text-3xl font-bold">
							<Trans>index:Services</Trans>
						</h1>
						<div className="grid sm:grid-cols-3 gap-4 lg:gap-16 xl:gap-20 xl:px-16">
							{SERVICES.map((service) => (
								<Link
									to={service.slug}
									className="w-full relative m-auto sm:h-345px lg:h-280px shadow-xl pt-4 mb-3 sm:px-4"
									key={service.title}
								>
									<div className="pl-12 sm:pl-0 md:pl-4 xl:pl-10">
										<div className="mb-2">
											<svg
												className="w-12 h-10 fill-current"
												viewBox="0 0 1792 1792"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d={service.icon} />
											</svg>
										</div>
										<div className="text-xl font-semibold ml-1 mb-5">{service.title}</div>
										<div className="mb-4">
											{service.items.map((item) => (
												<div className="text-lg mb-1 ml-1" key={item}>
													<span className="text-brand-green">/</span> {item}
												</div>
											))}
										</div>
										<div className="text-brand-green text-right block absolute inset-x-0 bottom-0 mb-4 mr-6 sm:mr-4 lg:mr-6">
											&#10230;
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				</div>
				{/* Certificates */}
				<div id="Certificates" className="bg-brand-gray4">
					<div className="container px-4 flex flex-row justify-center">
						<div className="w-1/3 sm:w-1/4 lg:w-64 my-16 mr-12 lg:mr-16">
							<Image fluid={imgMicrosoftFluid} alt={t("index:PCI-DSS certified")} className="w-full" />
						</div>
						<div className="w-1/3 sm:w-1/4 lg:w-56 my-16 pt-2">
							<Image fluid={imgPciDssFluid} alt="Microsoft silver platform" className="w-full" />
						</div>
					</div>
				</div>
				{/* We Are Hiring */}
				<div id="jobs" className="bg-brand-green text-white">
					<div className="container p-4">
						<h1 className="mb-4 text-3xl font-bold">
							<Trans>index:We Are Hiring</Trans>
						</h1>
						<Trans i18nKey="Dgital_is_providing" ns="index">
							<p className="mb-4 text-base">
								DGITAL is providing development, operations and consultation services for international
								airlines and online travel agencies. We are experts in Microsoft technologies especially
								in delivering high traffic and high availability solutions for cloud-based platforms.
							</p>
							<p className="mb-4 text-base">
								We value our people with excellent remuneration and compensation package and family
								friendly work conditions. We can be only as good as our team.
							</p>
						</Trans>
						<ul className="text-lg list-disc list-inside">
							{jobs
								.filter((j) => j.enabled)
								.map((j) => (
									<li key={j.id}>
										<Link to={`/jobs#${j.id}`}>{j.title}</Link>
									</li>
								))}
						</ul>
						<div className="text-right">
							<Link
								to="/jobs"
								className="text-xl text-white hover:text-brand-gray4 font-semibold uppercase inline"
							>
								<Trans>index:Apply now</Trans> &#10230;
							</Link>
						</div>
					</div>
				</div>
				{/* Executive Team */}
				<div id="executiveTeam" className="bg-white">
					<div className="container p-4">
						<h1 className="mb-4 text-3xl font-bold">
							<Trans>index:Executive Team</Trans>
						</h1>
						<div className="flex flex-col items-center lg:flex-row lg:items-start">
							{EXEC_TEAM.map((member) => (
								<div className="w-3/4 h-full pt-4 pb-8 lg:w-1/3 lg:px-16 text-center" key={member.id}>
									<article itemScope itemType="http://schema.org/Person">
										<header className="border-b border-brand-gray1 mb-5">
											<div style={{ width: "213px" }} className="inline-block">
												<Image itemProp="image" fluid={member.fluid} alt={member.alt} />
											</div>
											<h1 itemProp="name" className="font-light text-2xl mb-6">
												{member.name}
											</h1>
											<h2
												itemProp="jobTitle"
												className="border border-1 border-brand-gray1 uppercase text-base padding-2 inline-block font-semibold mb-4 p-1"
											>
												{member.position}
											</h2>
										</header>
										<p>{member.description}</p>
									</article>
								</div>
							))}
						</div>
					</div>
				</div>
				<Partners />
			</div>
		</Layout>
	);
};

export default IndexPage;
